import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  textDecoration: 'underline',
});

const linkTheme = defineStyleConfig({
  baseStyle,
});

export default linkTheme;
