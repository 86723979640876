import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  borderRadius: 0,
  width: { base: '100%', md: 'auto' },
  fontWeight: 400,
});

const icon = defineStyle((props) => ({
  ...props.theme.components.Button.variants.ghost(props),
  width: 'auto',
}));

const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'green',
  },
  baseStyle,
  variants: { icon },
});

export default buttonTheme;
