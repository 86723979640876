import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    paddingInlineStart: { base: 'sm', md: 'md' },
    borderRadius: 0,
  },
});

const inputTheme = defineMultiStyleConfig({ baseStyle });

export default inputTheme;
