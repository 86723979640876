import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type CreateFormsyClientInput = {
  name: Scalars['String']['input'];
  userId: Scalars['Float']['input'];
};

export type CreateFormsyFormInput = {
  clientId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Formsy = {
  __typename?: 'Formsy';
  config: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  formId: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  style: Scalars['JSON']['output'];
  version: Scalars['Float']['output'];
};

export type FormsyClient = {
  __typename?: 'FormsyClient';
  clientApiKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  forms: Array<FormsyForm>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type FormsyForm = {
  __typename?: 'FormsyForm';
  client: FormsyClient;
  clientId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  formsy?: Maybe<Array<Formsy>>;
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  idToken: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFormsyClient: FormsyClient;
  createFormsyForm: FormsyForm;
  createUser: User;
  login: LoginResponse;
  register: User;
  resendVerificationEmail: Scalars['Boolean']['output'];
  saveFormsy: Formsy;
  verifyEmail: Scalars['Boolean']['output'];
};


export type MutationCreateFormsyClientArgs = {
  options: CreateFormsyClientInput;
};


export type MutationCreateFormsyFormArgs = {
  options: CreateFormsyFormInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationRegisterArgs = {
  registerInput: RegisterInput;
};


export type MutationResendVerificationEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSaveFormsyArgs = {
  saveFormsyInput: SaveFormsyInput;
};


export type MutationVerifyEmailArgs = {
  id: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  formsy?: Maybe<Formsy>;
  formsyClient: FormsyClient;
  formsyClients: Array<FormsyClient>;
  formsyForm: FormsyForm;
  formsyForms: Array<FormsyForm>;
  formsys: Array<Formsy>;
  self: User;
  user?: Maybe<User>;
};


export type QueryFormsyClientArgs = {
  id: Scalars['Float']['input'];
};


export type QueryFormsyClientsArgs = {
  options: QueryFormsyClientsInput;
};


export type QueryFormsyFormArgs = {
  uid: Scalars['String']['input'];
};


export type QueryFormsyFormsArgs = {
  options: QueryFormsyFormsInput;
};


export type QueryFormsysArgs = {
  options: QueryFormsysInput;
};


export type QueryUserArgs = {
  id: Scalars['Float']['input'];
};

export type QueryFormsyClientsInput = {
  userId: Scalars['Float']['input'];
};

export type QueryFormsyFormsInput = {
  clientId?: InputMaybe<Scalars['Float']['input']>;
  formId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryFormsysInput = {
  formId: Scalars['String']['input'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type SaveFormsyInput = {
  config: Scalars['JSON']['input'];
  formId: Scalars['String']['input'];
  style: Scalars['JSON']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firebaseId: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
};

/** User role */
export enum UserRole {
  Admin = 'Admin',
  User = 'User'
}

export type CreateFormsyFormMutationVariables = Exact<{
  options: CreateFormsyFormInput;
}>;


export type CreateFormsyFormMutation = { __typename?: 'Mutation', createFormsyForm: { __typename?: 'FormsyForm', uid: string, createdAt: any, name: string, clientId: number } };

export type RegisterMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', id: number, firebaseId: string, email: string } };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: boolean };

export type ResendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', resendVerificationEmail: boolean };

export type CreateFormsyClientMutationVariables = Exact<{
  options: CreateFormsyClientInput;
}>;


export type CreateFormsyClientMutation = { __typename?: 'Mutation', createFormsyClient: { __typename?: 'FormsyClient', id: number, createdAt: any, name: string } };

export type FormsyClientsQueryVariables = Exact<{
  options: QueryFormsyClientsInput;
}>;


export type FormsyClientsQuery = { __typename?: 'Query', formsyClients: Array<{ __typename?: 'FormsyClient', id: number, clientApiKey: string, name: string, createdAt: any }> };

export type FormsyClientQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type FormsyClientQuery = { __typename?: 'Query', formsyClient: { __typename?: 'FormsyClient', id: number, clientApiKey: string, name: string, createdAt: any, forms: Array<{ __typename?: 'FormsyForm', uid: string, name: string, createdAt: any, updatedAt: any }> } };

export type FormsyFormQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;


export type FormsyFormQuery = { __typename?: 'Query', formsyForm: { __typename?: 'FormsyForm', uid: string, createdAt: any, name: string, client: { __typename?: 'FormsyClient', id: number, name: string }, formsy?: Array<{ __typename?: 'Formsy', id: number, createdAt: any, formId: string, config: any, style: any, version: number }> | null } };

export type SaveFormsyMutationVariables = Exact<{
  saveFormsyInput: SaveFormsyInput;
}>;


export type SaveFormsyMutation = { __typename?: 'Mutation', saveFormsy: { __typename?: 'Formsy', id: number, createdAt: any, formId: string, config: any, style: any, version: number } };

export type FormsysQueryVariables = Exact<{
  options: QueryFormsysInput;
}>;


export type FormsysQuery = { __typename?: 'Query', formsys: Array<{ __typename?: 'Formsy', id: number, createdAt: any, formId: string, config: any, style: any, version: number }> };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self: { __typename?: 'User', id: number, email: string, firebaseId: string, firstName?: string | null, lastName?: string | null } };


export const CreateFormsyFormDocument = gql`
    mutation createFormsyForm($options: CreateFormsyFormInput!) {
  createFormsyForm(options: $options) {
    uid
    createdAt
    name
    clientId
  }
}
    `;
export type CreateFormsyFormMutationFn = Apollo.MutationFunction<CreateFormsyFormMutation, CreateFormsyFormMutationVariables>;

/**
 * __useCreateFormsyFormMutation__
 *
 * To run a mutation, you first call `useCreateFormsyFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormsyFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormsyFormMutation, { data, loading, error }] = useCreateFormsyFormMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateFormsyFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormsyFormMutation, CreateFormsyFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormsyFormMutation, CreateFormsyFormMutationVariables>(CreateFormsyFormDocument, options);
      }
export type CreateFormsyFormMutationHookResult = ReturnType<typeof useCreateFormsyFormMutation>;
export type CreateFormsyFormMutationResult = Apollo.MutationResult<CreateFormsyFormMutation>;
export type CreateFormsyFormMutationOptions = Apollo.BaseMutationOptions<CreateFormsyFormMutation, CreateFormsyFormMutationVariables>;
export const RegisterDocument = gql`
    mutation register($email: String!, $password: String!) {
  register(registerInput: {email: $email, password: $password}) {
    id
    firebaseId
    email
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($token: String!) {
  verifyEmail(id: $token)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($email: String!) {
  resendVerificationEmail(email: $email)
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const CreateFormsyClientDocument = gql`
    mutation createFormsyClient($options: CreateFormsyClientInput!) {
  createFormsyClient(options: $options) {
    id
    createdAt
    name
  }
}
    `;
export type CreateFormsyClientMutationFn = Apollo.MutationFunction<CreateFormsyClientMutation, CreateFormsyClientMutationVariables>;

/**
 * __useCreateFormsyClientMutation__
 *
 * To run a mutation, you first call `useCreateFormsyClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormsyClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormsyClientMutation, { data, loading, error }] = useCreateFormsyClientMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateFormsyClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormsyClientMutation, CreateFormsyClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormsyClientMutation, CreateFormsyClientMutationVariables>(CreateFormsyClientDocument, options);
      }
export type CreateFormsyClientMutationHookResult = ReturnType<typeof useCreateFormsyClientMutation>;
export type CreateFormsyClientMutationResult = Apollo.MutationResult<CreateFormsyClientMutation>;
export type CreateFormsyClientMutationOptions = Apollo.BaseMutationOptions<CreateFormsyClientMutation, CreateFormsyClientMutationVariables>;
export const FormsyClientsDocument = gql`
    query formsyClients($options: QueryFormsyClientsInput!) {
  formsyClients(options: $options) {
    id
    clientApiKey
    name
    createdAt
  }
}
    `;

/**
 * __useFormsyClientsQuery__
 *
 * To run a query within a React component, call `useFormsyClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsyClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsyClientsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useFormsyClientsQuery(baseOptions: Apollo.QueryHookOptions<FormsyClientsQuery, FormsyClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsyClientsQuery, FormsyClientsQueryVariables>(FormsyClientsDocument, options);
      }
export function useFormsyClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsyClientsQuery, FormsyClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsyClientsQuery, FormsyClientsQueryVariables>(FormsyClientsDocument, options);
        }
export function useFormsyClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsyClientsQuery, FormsyClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsyClientsQuery, FormsyClientsQueryVariables>(FormsyClientsDocument, options);
        }
export type FormsyClientsQueryHookResult = ReturnType<typeof useFormsyClientsQuery>;
export type FormsyClientsLazyQueryHookResult = ReturnType<typeof useFormsyClientsLazyQuery>;
export type FormsyClientsSuspenseQueryHookResult = ReturnType<typeof useFormsyClientsSuspenseQuery>;
export type FormsyClientsQueryResult = Apollo.QueryResult<FormsyClientsQuery, FormsyClientsQueryVariables>;
export const FormsyClientDocument = gql`
    query formsyClient($id: Float!) {
  formsyClient(id: $id) {
    id
    clientApiKey
    name
    createdAt
    forms {
      uid
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useFormsyClientQuery__
 *
 * To run a query within a React component, call `useFormsyClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsyClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsyClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormsyClientQuery(baseOptions: Apollo.QueryHookOptions<FormsyClientQuery, FormsyClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsyClientQuery, FormsyClientQueryVariables>(FormsyClientDocument, options);
      }
export function useFormsyClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsyClientQuery, FormsyClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsyClientQuery, FormsyClientQueryVariables>(FormsyClientDocument, options);
        }
export function useFormsyClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsyClientQuery, FormsyClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsyClientQuery, FormsyClientQueryVariables>(FormsyClientDocument, options);
        }
export type FormsyClientQueryHookResult = ReturnType<typeof useFormsyClientQuery>;
export type FormsyClientLazyQueryHookResult = ReturnType<typeof useFormsyClientLazyQuery>;
export type FormsyClientSuspenseQueryHookResult = ReturnType<typeof useFormsyClientSuspenseQuery>;
export type FormsyClientQueryResult = Apollo.QueryResult<FormsyClientQuery, FormsyClientQueryVariables>;
export const FormsyFormDocument = gql`
    query formsyForm($uid: String!) {
  formsyForm(uid: $uid) {
    uid
    createdAt
    name
    client {
      id
      name
    }
    formsy {
      id
      createdAt
      formId
      config
      style
      version
    }
  }
}
    `;

/**
 * __useFormsyFormQuery__
 *
 * To run a query within a React component, call `useFormsyFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsyFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsyFormQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFormsyFormQuery(baseOptions: Apollo.QueryHookOptions<FormsyFormQuery, FormsyFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsyFormQuery, FormsyFormQueryVariables>(FormsyFormDocument, options);
      }
export function useFormsyFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsyFormQuery, FormsyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsyFormQuery, FormsyFormQueryVariables>(FormsyFormDocument, options);
        }
export function useFormsyFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsyFormQuery, FormsyFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsyFormQuery, FormsyFormQueryVariables>(FormsyFormDocument, options);
        }
export type FormsyFormQueryHookResult = ReturnType<typeof useFormsyFormQuery>;
export type FormsyFormLazyQueryHookResult = ReturnType<typeof useFormsyFormLazyQuery>;
export type FormsyFormSuspenseQueryHookResult = ReturnType<typeof useFormsyFormSuspenseQuery>;
export type FormsyFormQueryResult = Apollo.QueryResult<FormsyFormQuery, FormsyFormQueryVariables>;
export const SaveFormsyDocument = gql`
    mutation saveFormsy($saveFormsyInput: SaveFormsyInput!) {
  saveFormsy(saveFormsyInput: $saveFormsyInput) {
    id
    createdAt
    formId
    config
    style
    version
  }
}
    `;
export type SaveFormsyMutationFn = Apollo.MutationFunction<SaveFormsyMutation, SaveFormsyMutationVariables>;

/**
 * __useSaveFormsyMutation__
 *
 * To run a mutation, you first call `useSaveFormsyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormsyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormsyMutation, { data, loading, error }] = useSaveFormsyMutation({
 *   variables: {
 *      saveFormsyInput: // value for 'saveFormsyInput'
 *   },
 * });
 */
export function useSaveFormsyMutation(baseOptions?: Apollo.MutationHookOptions<SaveFormsyMutation, SaveFormsyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFormsyMutation, SaveFormsyMutationVariables>(SaveFormsyDocument, options);
      }
export type SaveFormsyMutationHookResult = ReturnType<typeof useSaveFormsyMutation>;
export type SaveFormsyMutationResult = Apollo.MutationResult<SaveFormsyMutation>;
export type SaveFormsyMutationOptions = Apollo.BaseMutationOptions<SaveFormsyMutation, SaveFormsyMutationVariables>;
export const FormsysDocument = gql`
    query formsys($options: QueryFormsysInput!) {
  formsys(options: $options) {
    id
    createdAt
    formId
    config
    style
    version
  }
}
    `;

/**
 * __useFormsysQuery__
 *
 * To run a query within a React component, call `useFormsysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useFormsysQuery(baseOptions: Apollo.QueryHookOptions<FormsysQuery, FormsysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsysQuery, FormsysQueryVariables>(FormsysDocument, options);
      }
export function useFormsysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsysQuery, FormsysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsysQuery, FormsysQueryVariables>(FormsysDocument, options);
        }
export function useFormsysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsysQuery, FormsysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsysQuery, FormsysQueryVariables>(FormsysDocument, options);
        }
export type FormsysQueryHookResult = ReturnType<typeof useFormsysQuery>;
export type FormsysLazyQueryHookResult = ReturnType<typeof useFormsysLazyQuery>;
export type FormsysSuspenseQueryHookResult = ReturnType<typeof useFormsysSuspenseQuery>;
export type FormsysQueryResult = Apollo.QueryResult<FormsysQuery, FormsysQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    email
    firebaseId
    firstName
    lastName
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export function useSelfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfSuspenseQueryHookResult = ReturnType<typeof useSelfSuspenseQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;