import breakpoints from './breakpoints';
import sizes from './sizes';
import space from './space';
import transitions from './transitions';
import typography from './typography';

const theme = {
  breakpoints,
  space,
  ...typography,
  transitions,
  header: {
    height: 64,
  },
  sizes,
};

export type Theme = typeof theme;

export default theme;
