import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  global: (props) => ({
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      fontFamily: 'body',
      fontSize: 'base',
      backgroundColor: 'bg',
      color: 'text',
      height: '100%',
    },

    body: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      margin: '0px',
    },

    '#__next': {
      height: '100%',
    },

    // Utils
    '.disable-scroll': {
      overflowY: 'hidden',
    },
  }),
};

export default styles;
