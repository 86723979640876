import buttonTheme from './button';
import { cardTheme } from './card';
import inputTheme from './input';
import linkTheme from './link';
import radioTheme from './radio';

export default {
  Input: inputTheme,
  Button: buttonTheme,
  Link: linkTheme,
  Card: cardTheme,
  Radio: radioTheme,
};
